
import { Component, Vue, Watch } from "vue-property-decorator";
import i18n from "../../plugins/i18n";

import formatDate from "../../plugins/formatDate";

import StarterService from "../../services/sportevent/StarterService";
import Starter from "../../models/sportevent/Starter";
import SporteventService from "../../services/sportevent/SporteventService";
import Person from "@/models/societymgmt/Person";

@Component({
  filters: {
    formatDate,
  },
})
export default class StartersComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: Starter[] = [];
  public persons: Person[] = [];
  public fields = [
    {
      key: "person",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "society",
      sortable: true,
      label: i18n.tc("societymgmt.society"),
    },
    {
      key: "sporteventCompetition",
      sortable: true,
      label: i18n.tc("sporteventCalculation.competition"),
    },
    {
      key: "actions",
      sortable: false,
      class: "text-center",
      label: "",
    },
  ];
  public filter = "";
  public perPage = 50;
  public pageOptions = [50, 100, 200, 300];
  public totalRows = 0;
  public currentPage = 1;
  public importerrors = "";

  async getAll(reload = false): Promise<void> {
    this.loading = true;
    this.items = [];
    this.items = await StarterService.getAll(reload);
    // get unique persons from starters
    this.items.forEach((starter) => {
      if (this.persons.findIndex((p) => p.id === starter.person.id) === -1) {
        this.persons.push(starter.person);
      }
    });
    this.loading = false;
  }

  async deleteStarter(starter: Starter): Promise<void> {
    if (starter.id !== undefined) {
      this.loading = true;
      await StarterService.delete(starter.id);
    }
    this.getAll();
  }

  @Watch("$route", { immediate: true, deep: true })
  async onUrlChange(): Promise<void> {
    this.items = await StarterService.getAll();
    this.items.forEach((starter) => {
      if (this.persons.findIndex((p) => p.id === starter.person.id) === -1) {
        this.persons.push(starter.person);
      }
    });
  }

  mounted(): void {
    this.getAll();
  }

  // CSV Import
  public csvFormData = new FormData();
  handleFileUpload(event: any) {
    this.csvFormData.append(
      "starterXlsx",
      event.target.files[0],
      event.target.files[0].name
    );
  }

  uploadCsv(): void {
    this.loading = true;
    const sportevent = SporteventService.get();
    if (sportevent.id !== undefined) {
      StarterService.uploadCsv(sportevent.id, this.csvFormData).then(
        (response) => {
          if (response === undefined) return;
          this.importerrors = response.importerrors;
          this.getAll(true);
          this.loading = false;
        }
      );
    }
  }

  CustomFilterTable(row: Starter, filter: string): boolean {
    filter = filter.toLowerCase();
    row = new Starter(row);
    if (
      row.NameInclude(filter) ||
      row.startsForSociety.name.toLowerCase().includes(filter)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
